<template>
  <div class="login">
    <div class="login__wrapper">
      <div class="login-body">
        <div class="login-body__header">
          <img src="@/assets//images/logo.svg" style="width: 315px" />
        </div>
        <div class="login-body__main">
          <input
            v-model="dataUser.login"
            type="text"
            class="login-body__main_login"
            placeholder="Логин"
          />
          <input
            v-model="dataUser.password"
            type="password"
            class="login-body__main_password"
            placeholder="Пароль"
          />
        </div>
        <div class="login-body__buttons">
          <div
            class="login-body__buttons_accept"
            @click="autUser"
            @keyup.enter="autUser"
          >
            Дать газу...
          </div>
        </div>
      </div>
    </div>
      <ModalLoginError
    v-if="modalLoginError"
    @closeModalLoginError="closeModalLoginError"
    :textError="textError"/>
  </div>

</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useLogin } from "@/composition/useLogin.js";
import { useApi } from "@/composition/useApi.js";
import config from '/config.json'
import ModalLoginError from "@/components/Modals/ModalLoginError.vue";
export default {
  created() {
    document.title = "Авторизация | Альянс-ЮГ";
  },
  components: {
    ModalLoginError,
  },
  setup() {
    const { loginUser, authUser } = useLogin();
    const { tokenCSRF } = useApi();

    const dataUser = ref({
      login: "",
      password: "",
    });

    const headers = ref({})
    const textError = ref(null)
    const autUser = () => {

      loginUser(dataUser.value, {
        'withCredentials': true,
        'headers': headers.value
        })
        .then(() => {
            window.location.href = config.REDIRECT_URL
        })
        .catch((e) => {
          if(e.response.data.statusCode == 402)
            textError.value = e.response.data.message
          tokenCSRF.value = undefined
          modalLoginError.value = true;
        });
    };
    const sendRequestCookies = ()=>{
      const xhr = new XMLHttpRequest();
      xhr.onload=()=>{
        tokenCSRF.value = JSON.parse(xhr.response).token
        headers.value = {
          "csrf-token": tokenCSRF.value
        }
      }
      xhr.open('GET', `${config.API_URL}/csrf/`, true);
      xhr.withCredentials = true;
      xhr.send(null);
    }

    onMounted(() => {
      sendRequestCookies()
      document.addEventListener("keydown", function (e) {
        if (e.code == "Enter") autUser();
      });
    });
    onBeforeUnmount(() => {
      document.removeEventListener("keydown", autUser);
    });
    
    const isAuth = ref(true)
    watch(() => {
      if (tokenCSRF.value) {
        if(isAuth.value)
          authUser(tokenCSRF.value)
            .then((r)=>{
              if(r.status == 200)
                window.location.href = config.REDIRECT_URL
            })
        isAuth.value = false

        setTimeout(()=>{
          sendRequestCookies()
        },210000)
      }
    });
    const modalLoginError = ref(false);
    const closeModalLoginError = () => modalLoginError.value = false;
    return {
      textError,
      dataUser,
      autUser,
      isAuth,
      modalLoginError,
      closeModalLoginError,
      sendRequestCookies
    };
  },
};
</script>
<style lang="scss" src="@/assets/styles/login.scss"></style>

