<template>
<div class="modal-login-error">
    <div class="modal-login-error-body">
        <div class="modal-login-error-body__text">{{textError? textError:'Пожалуйста проверьте корректность введенных данных и повторите попытку'}}</div>
        <div class="modal-login-error-body__buttons">
            <div class="modal-login-error-body__buttons_accept" 
                @click="closeModalLoginError">OK</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        textError:{
            type: String,
            default: null
        }
    },
    setup(_props, {emit}) {
        const closeModalLoginError = () => {
            emit('closeModalLoginError')
        }
        return {
            closeModalLoginError
        }
    }
}
</script>
<style lang="scss">
.modal-login-error {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #08163AB8;
    width: 100%;
    height: 100%;
   z-index: 9999;
}
.modal-login-error-body {
    background: #FFFFFF;
    box-shadow: 0px 8px 8px rgba(47, 50, 66, 0.04), 0px 12px 28px rgba(47, 50, 66, 0.12);
    border-radius: 8px;
    width: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    &__text {
        color: #35384A;
        font-size: 24px;
        font-weight: 600;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        &_accept {
            color: #F6F7FB;
            background: #DA2E35;
            border: 1px solid #DA2E35;
            line-height: 40px;
            padding: 0px 42.5px;
            border-radius: 8px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
</style>
