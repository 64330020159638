
import axios from 'axios'
import { useApi } from "@/composition/useApi.js"
export function useLogin() {
    const { api } = useApi()

    const loginUser = (dataUser, params) => {
        let body = {
            ...dataUser,
        }
        return axios.post(api + '/auth/login', body, params)
    }
    const authUser = (tokenCSRF)=>{
        let params = {
            'withCredentials': true,
            'headers': {
                "csrf-token": tokenCSRF
            }  
        }
        return axios.get (api + '/auth/user', params)
    }
    return {
        loginUser,
        authUser
    }
}