// import * as dotenv from 'dotenv'
import config from '/config.json'
import {ref}  from 'vue'

export function useApi() {
    const api = config.API_URL
    const tokenCSRF = ref()
        return {
            api,
            tokenCSRF,
        }
    }