<template>
<div class="wrapper">
  <router-view/>  
</div>

</template>

<style>
#app {
  font-family: "Vela Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
@font-face {
  font-family: "Vela Sans";
  src: url("@/assets/fonts/VelaSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
</style>
